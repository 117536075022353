@import "../node_modules/normalize.css/normalize.css";
@import "./assets/fonts/FavoritMono-Medium/FavoritMono-Medium.css";
@import "./assets/fonts/NeueMontreal-Bold/NeueMontreal-Bold.css";
@import "./assets/fonts/NeueMontreal-Medium/NeueMontreal-Medium.css";

/* Damien's reset ™️ */

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Neue Montreal Medium", Helvetica, Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

noscript {
  z-index: 9999;
  position: fixed;
  top: 30px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-weight: normal;
}

a,
a:visited {
  text-decoration: none;
}

ul,
ol,
dl,
dt,
dd {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}
img {
  vertical-align: middle;
}
figure {
  margin: 0;
}
i {
  font-style: normal;
}
b,
strong {
  font-weight: normal;
}

::selection {
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.2);
}
* {
  /* user-select: none; */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
}

#root {
  display: flex;
  min-height: 100%;
}

.PageTransition-enter > .MuiBox-root {
  pointer-events: none;
  opacity: 0;
  transform: translate3d(0, 2vh, 0);
}

.PageTransition-enter.PageTransition-enter-active > .MuiBox-root {
  pointer-events: initial;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 0.5s ease-in 0.5s, transform 0.5s ease-in-out 0.5s;
}

.PageTransition-exit > .MuiBox-root {
  pointer-events: none;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.PageTransition-exit.PageTransition-exit-active > .MuiBox-root {
  pointer-events: none;
  opacity: 0;
  transform: translate3d(0, 2vh, 0);
  transition: opacity 0.3s ease-in, transform 0.3s ease-in-out;
}

.PageTransition-enter > a {
  pointer-events: none;
  opacity: 0;
}

.PageTransition-enter.PageTransition-enter-active > a {
  pointer-events: initial;
  opacity: 1;
  transition: opacity 0.5s ease-in 0.5s;
}

.PageTransition-exit > a {
  pointer-events: none;
  opacity: 1;
}

.PageTransition-exit.PageTransition-exit-active > a {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
